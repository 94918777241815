var Calendar = function($wrapper) {

    var self = this;

    self.$wrapper = $wrapper;

    self.occupied = [];

    self.startDate = moment().startOf('month').startOf('week');

    self.endDate = moment().startOf('month').add(12, 'month').endOf('week');

    self.loading = false;

    self.template = _.template($('#template-calendar').html());

    self.parseData = function(data) {
        _.each(data, function(timestamp) {
            self.occupied.push(moment(timestamp).startOf('day'));
        });
    }

    self.fetchOccupied = function(callback) {
        self.loading = true;
        $.ajax({
            method: 'GET',
            url: '/' + CONFIG.locale + '/ajax/occupied',
            data: {
                start: self.startDate.valueOf(),
                end: self.endDate.valueOf()
            },
            success: function(data) {
                self.parseData(data);
                self.loading = true;
                if (_.isFunction(callback)) {
                    callback(data);
                }
            }
        })
    };

    self.isOccupied = function(date) {
        for(var i = 0; i < self.occupied.length; i++) {
            if (date.isSame(self.occupied[i], 'day')) {
                return true;
            }
        }

        return false;
    }

    self.render = function() {
        var html = self.template({data: self});
        self.$wrapper.html(html);
    }

    self.init = function() {
        self.fetchOccupied(self.render);
    }

    self.init();

};