var MapFactory = (function () {
    var instance;


    var Map = function () {

        var self = this;

        self.$wrapper = document.getElementById('map');

        self.strouanne = new google.maps.LatLng(50.903061, 1.681081);

        self.style = [
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [{"lightness": 100}, {"visibility": "simplified"}]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{"visibility": "on"}, {"color": "#C9D5E4"}]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [{"color": "#C5E3BF"}]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [{"color": "#D1D1B8"}]
            }
        ];

        self.options = {
            scrollwheel: false,
            zoom: 10,
            center: self.strouanne,
            styles: self.style,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        };




        self.marker = new google.maps.Marker(
            {
                position: self.strouanne,
                animation: google.maps.Animation.DROP,
                icon: '/img/marker.png'
            }
        );

        self.showMarker = function () {
            self.marker.setMap(self.map);
        };



        self.render =  function() {
            self.map = new google.maps.Map(self.$wrapper, self.options);
            self.showMarker();
        }
    };

    function createInstance() {
        var map = new Map();
        map.render();
        return map;
    }

    return {
        getInstance: function () {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };



})();


