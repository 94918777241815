(function () {
    "use strict";

    $(document).ready(
        function () {
            // set the calendar locale
            moment.locale(CONFIG.locale);

            // initialize the components
            // -- calendar
            var calendar = new Calendar($('#availability-calendar'));

            // -- map
            var map = MapFactory.getInstance();

            // -- gallery
            $('.gallery a').featherlightGallery(
                {
                    openSpeed: 200,
                    previousIcon: '‹',
                    nextIcon: '›',
                    galleryFadeIn: 200,
                    galleryFadeOut: 200
                    //afterContent: function(event) {
                    //    $('.featherlight-content').prepend($('<a data-pin-do="buttonBookmark" data-pin-color="white" data-pin-tall="true" href="https://www.pinterest.com/pin/create/button/"><img src="//assets.pinterest.com/images/pidgets/pinit_fg_en_rect_white_28.png" /></a>'));
                    //    console.log(event);
                    //}
                }
            );

            // -- smooth scroll
            $(
                function () {
                    $('a[href*="#"]:not([href="#"])').click(
                        function () {
                            if (location.pathname.replace(/^\//, '') == this.pathname.replace(
                                    /^\//,
                                    ''
                                ) && location.hostname == this.hostname) {
                                var target = $(this.hash);
                                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                                if (target.length) {
                                    $('html, body').animate(
                                        {
                                            scrollTop: target.offset().top
                                        }, 300
                                    );
                                    return false;
                                }
                            }
                        }
                    );
                }
            );
        }
    );
})();
